<template>
  <div>redirecting...</div>
</template>

<script>
import { onMounted, watchEffect } from '@vue/composition-api';
import storage from '../config/localStorage';
import router from '../router';
import store from '../store';

export default {
  setup(props) {
    watchEffect(() => {
      if (props.token) {
        storage.set('access_token', props.token);
        store.commit('setLoggedIn', true);
        router.replace({ name: 'Dashboard' });
      }
    });
  },
  props: {
    token: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
